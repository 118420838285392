"use client";
import Image from "next/image";
import { GoArrowRight } from "react-icons/go";
import { Inter } from "next/font/google";
import { motion } from "framer-motion";
import { useState } from "react";
import { useRouter } from "@/i18n/routing";
import { useTranslations } from "next-intl";

const inter = Inter({
  subsets: ["latin"],
  weight: ["400", "700"],
});

export const Hero = () => {
  const [hover, setHover] = useState(false);

  const router = useRouter();

  const t = useTranslations("LandingPage");
  const b = useTranslations("Buttons");

  return (
    <section
      className="w-full bg-[url('/background_hero.png')] bg-cover bg-center bg-no-repeat overflow-x-clip"
      aria-labelledby="hero-heading"
    >
      <div className="bg-white/60 pt-[190px] pb-20 px-5 md:pt-[190px] md:pb-20 md:p-20 lg:px-40">
        <div className="m-auto w-full ">
          <div className="md:flex w-full items-center justify-between">
            <div className="md:w-[478px] md:flex-1">
              <h1
                id="hero-heading"
                className={`${inter.className} text-5xl md:text-7xl font-bold tracking-tighter`}
              >
                {t.rich("Hero.title")}
              </h1>
              <p className="text-xl font-normal tracking-tight text-[#080808] mt-6">
                {t.rich("Hero.description")}
              </p>
              <div className="flex gap-1 items-center mt-[30px]">
                <button
                  aria-label="Register your interest in Deskadora"
                  className="btn btn-secondary"
                  onClick={() => router.push("/register")}
                >
                  {b.rich("register_your_interest")}
                </button>
                <button
                  aria-label="Learn more about Deskadora"
                  className="btn btn-outline gap-1 items-center"
                  onMouseEnter={() => setHover(true)}
                  onMouseLeave={() => setHover(false)}
                  onClick={() => router.push("/about")}
                >
                  <span>{b.rich("learn_more")}</span>
                  <motion.span
                    className="inline-flex"
                    animate={{ x: hover ? [0, 4, 0] : 0 }}
                    transition={{ duration: 0.5 }}
                  >
                    <GoArrowRight className="text-[#080808] w-5 h-5 inline-flex justify-center items-center" />
                  </motion.span>
                </button>
              </div>
            </div>
            <div className="mt-20 md:mt-0 md:h-[648px] md:flex-1 md:relative">
              <Image
                alt="Mockup showcasing the Deskadora contactless payment solution"
                width={600}
                height={509}
                src={"/hero_image.png"}
                className="md:absolute md:h-full md:w-auto md:max-w-none md:left-8"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
