"use client";

import { Check } from "lucide-react";
import { twMerge } from "tailwind-merge";
import { motion } from "framer-motion";
import { Inter } from "next/font/google";
import { useTranslations } from "next-intl";
import { useRouter } from "@/i18n/routing";

const inter = Inter({
  subsets: ["latin"],
  weight: ["400", "700"],
});

export const Plans = () => {
  const t = useTranslations("LandingPage");
  const b = useTranslations("Buttons");
  const g = useTranslations("Global");

  const router = useRouter();

  const PLANS = [
    {
      title: "Free",
      price: "0",
      currency: g.rich("currencySymbol"),
      features: [
        "QR Code Payments",
        "POS Integration",
        "Reviews Enhancement",
        `1.5% + ${g.rich("currencySymbol")}0.30/transaction`,
      ],
      popular: false,
      inverse: false,
    },
    {
      title: "Basic",
      price: "59",
      currency: g.rich("currencySymbol"),
      features: [
        "Analytics",
        "QR Code Payments",
        "POS Integration",
        "Reviews Enhancement",
        `1.5% + ${g.rich("currencySymbol")}0.30/transaction`,
      ],
      popular: true,
      inverse: true,
    },
    {
      title: "Pro",
      price: "99",
      currency: g.rich("currencySymbol"),
      features: [
        "Shift Planning",
        "Booking System",
        "Digital Menus",
        "Analytics",
        "QR Code Payments",
        "POS Integration",
        "Reviews Enhancement",
        `1.5% + ${g.rich("currencySymbol")}0.30/transaction`,
      ],
      popular: false,
      inverse: false,
    },
  ];

  return (
    <section
      id="plans"
      className="mt-[80px] bg-[url('/background_hero.png')] bg-cover bg-center bg-no-repeat overflow-x-clip"
      aria-labelledby="plans-heading"
    >
      <div className="flex-col gap-5 w-full items-center justify-center bg-white/60 py-[30px]">
        <div className="container m-auto flex-col flex gap-5 w-full justify-center items-center">
          <div className="section-heading">
            <div className="flex w-full items-center justify-center">
              <p className="bg-[#c8e854] text-black text-[14px] rounded-md px-4 py-[1]">
                {t.rich("Plans.label")}
              </p>
            </div>
            <h2
              id="plans-heading"
              className={`${inter.className} text-center text-black text-3xl font-bold tracking-tight mt-5`}
            >
              {t.rich("Plans.title")}
            </h2>
            <p className="text-center text-black mt-5 text-lg leading-[30px] tracking-tight">
              {t.rich("Plans.description")}
            </p>
          </div>
          <div className="flex flex-col mt-10 gap-6 items-center lg:flex-row lg:items-end lg:justify-center">
            {PLANS.map(
              ({ title, price, currency, features, inverse, popular }) => (
                <div
                  key={title}
                  className={twMerge(
                    "px-10 py-5 w-full text-[#080808] rounded-xl",
                    inverse === true && "bg-black text-[#BCBCBC]"
                  )}
                >
                  <div className="flex justify-between">
                    <h3 className="text-lg font-bold">{title}</h3>
                    {popular && (
                      <div className="inline-flex text-sm px-4 p-1.5 border rounded-md border-white/20">
                        <motion.span
                          animate={{ backgroundPositionX: "-100%" }}
                          transition={{
                            duration: 2,
                            repeat: Infinity,
                            ease: "linear",
                            repeatType: "loop",
                          }}
                          className="bg-[linear-gradient(to_right,#DD7DDF,#E1CD86,#BBCB92,#71C2EF,#3BFFFF,#DD7DDF,#E1CD86,#BBCB92,#71C2EF,#3BFFFF)] [background-size:200%] text-transparent bg-clip-text font-medium"
                        >
                          Popular
                        </motion.span>
                      </div>
                    )}
                  </div>
                  <div className="flex items-baseline gap-1 mt-[30px]">
                    <span className="text-4xl font-bold tracking-tighter">
                      {currency}
                      {price}
                    </span>
                    <span className="tracking-tight">/{g.rich("month")}</span>
                  </div>
                  <button
                    className="btn btn-primary w-full mt-[30px]"
                    aria-label={`Get started with the ${title} plan`}
                    onClick={() => router.push("/register")}
                  >
                    {b.rich("get_started")}
                  </button>
                  <ul className="flex flex-col gap-5 mt-[32px]">
                    {features.map((feature) => (
                      <li
                        key={feature}
                        className="text-sm flex items-center gap-4"
                      >
                        <Check className="h-6 w-6" aria-hidden="true" />
                        <span>{feature}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )
            )}
          </div>
        </div>
      </div>
    </section>
  );
};
