"use client";

import { useTranslations } from "next-intl";
import { Inter } from "next/font/google";
import { useRouter } from "@/i18n/routing";

const inter = Inter({
  subsets: ["latin"],
  weight: ["200", "400", "600", "700"],
});

export const GetStarted = () => {
  const router = useRouter();

  const t = useTranslations("LandingPage");
  const b = useTranslations("Buttons");

  return (
    <section
      id="get-started"
      className="bg-white/60 pt-[20px]"
      aria-labelledby="get-started-heading"
    >
      <div className="container m-auto max-w-[1200px]">
        <div className="flex-col gap-5 py-10 w-full items-center justify-center bg-[#d8b4fe] bg-[url('/pattern_logo.png')] bg-cover bg-center bg-no-repeat rounded-md">
          <div className="container m-auto flex-col flex gap-5 w-full justify-center items-center">
            <h2
              id="get-started-heading"
              className={`${inter.className} text-center text-3xl font-bold tracking-tight mt-5`}
            >
              {t.rich("StartToday.title")}
            </h2>
            <p className="text-center mt-5 text-lg leading-[30px] tracking-tight text-[#080808]">
              {t.rich("StartToday.description")}
            </p>
            <button
              className="btn btn-secondary"
              aria-label="Register your interest in Deskadora"
              onClick={() => router.push(`/register`)}
            >
              {b.rich("register_your_interest")}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
