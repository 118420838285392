import(/* webpackMode: "eager", webpackExports: ["GetStarted"] */ "/user/src/app/app/[locale]/(root)/sections/GetStarted.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Hero"] */ "/user/src/app/app/[locale]/(root)/sections/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Plans"] */ "/user/src/app/app/[locale]/(root)/sections/Plans.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/user/src/app/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/user/src/app/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/user/src/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/user/src/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/user/src/app/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/(root)/sections/ClientFeatures.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"200\",\"400\",\"600\",\"700\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/user/src/app/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/(root)/sections/Introduction.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/user/src/app/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/(root)/sections/VenueFeatures.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/user/src/app/node_modules/next/font/google/target.css?{\"path\":\"app/[locale]/(root)/sections/WhyUs.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"700\"]}],\"variableName\":\"inter\"}");
